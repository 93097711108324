import { Chip, Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import DataTable from '../../../../components/DataTable/DataTable';
import '../LeadEstimates/ImportantMetrics.scss';
import CustomSkeleton from '../../../../components/SkeletonLoading/SkeletonLoading';
import { Suspense, useEffect, useRef, useState } from 'react';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import ToolTipOnText from '../../../../components/Tooltip/Tooltip';
import { useDateRange } from '../../../../components/DateTimePicker/DateRangeContext';
import useToast from '../../../../components/Toast/hooks/useToast';
import ShareThroughEmail from '../../ShareThroughEmail';
import useApiService from '../../../../services/api.service';
import PredictiveScoringEndpoints from '../../PredictiveScoringEndpoints';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AlertDialog from '../../../../components/Dialog/Dialog';
import PageLoader from '../../../../components/pageLoader/pageLoader';

const ScoreBoard = (props: any, ref: any) => {
	const theme: any = useTheme();
	const [selectedGrade, setSelectedGrade] = useState('overall');
	const [selectedFilter, setSelectedFilter] = useState('All');
	const [Data, setData]: any = useState();
	const [TotalRows, setTotalRows]: any = useState();
	const [isTableExport, setIsTableExport] = useState(true);
	const APIService: any = useApiService();
	const [mailArray, setmailArray] = useState();
	const [hitapi, sethitapi]: any = useState(false);
	const shareAttachmentRef = useRef<any>();
	const toaster: any = useToast();
	const [currentRequest, setCurrentRequest] = useState('');
	const { dateRange } = useDateRange();
	const [level, setlevel] = useState(() => {
		return parseInt(localStorage.getItem('level') || '1');
	});

	const getTableExportNamesArray = (array: any) => {
		setmailArray(array);
	};

	const getTableExportEmailValue = (apihit: boolean) => {
		sethitapi(apihit);
	};

	const shareAttachmentHTML = () => (
		<Grid xs={12} container>
			<Grid sx={{ minWidth: 600, position: 'relative' }}>
				<Grid container padding={'0px 20px'}>
					<ShareThroughEmail
						isTableExport={isTableExport}
						getTableExportNamesArray={getTableExportNamesArray}
						getTableExportEmailValue={getTableExportEmailValue}
						flag={currentRequest}
						toaster={toaster}
						closePopup={(data: any) => {
							if (shareAttachmentRef.current) {
								shareAttachmentRef.current.handleClose();
							}
						}}
					/>
				</Grid>
			</Grid>
		</Grid>
	);

	const sendCSV = async () => {
		const request = {
			reqBody: {
				level: level + 1,
				start_date: dateRange.start_date,
				end_date: dateRange.end_date,
				stage: selectedFilter,
				grade:
					selectedGrade.toLowerCase() == 'overall'
						? 1
						: selectedGrade.toLowerCase() == 'grade a'
						? 2
						: selectedGrade.toLowerCase() == 'grade b'
						? 3
						: selectedGrade.toLowerCase() == 'grade c'
						? 4
						: 1,
				sort: 1,
				recipient_email: mailArray,
			},
		};
		try {
			const res = await APIService.post(
				PredictiveScoringEndpoints.leadScoringCSV(request),
				true
			);
			toaster.addToast({
				message: 'Please check your mail.',
				timeout: 3000,
				type: 'success',
			});
		} catch (err) {
			toaster.addToast({
				message: 'Something went wrong',
				timeout: 3000,
				type: 'error',
			});
			console.error(err);
		} finally {
			sethitapi(false);
			if (shareAttachmentRef.current) {
				shareAttachmentRef.current.handleClose();
			}
		}
	};

	const getDataForGrade = (grade: string) => {
		switch (grade.toLowerCase()) {
			case 'grade a':
				return props?.Data?.A?.lead_score_dict;
			case 'grade b':
				return props?.Data?.B?.lead_score_dict;
			case 'grade c':
				return props?.Data?.C?.lead_score_dict;
			case 'overall':
				return props?.Data?.lead_score_dict;
			default:
				return null;
		}
	};

	const getTotalRows = (grade: string) => {
		switch (grade) {
			case 'grade a':
				return props?.Data?.A?.size;
			case 'grade b':
				return props?.Data?.B?.size;
			case 'grade c':
				return props?.Data?.C?.size;
			case 'overall':
				return props?.Data?.size;
			default:
				return null;
		}
	};

	useEffect(() => {
		if (hitapi == true) {
			sendCSV();
		}
	}, [hitapi]);

	useEffect(() => {
		setData(getDataForGrade(selectedGrade.toLowerCase()));
		setTotalRows(getTotalRows(selectedGrade.toLowerCase()));
	}, [props, selectedGrade, selectedFilter]); // Added selectedFilter dependency

	const handleGradeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setSelectedGrade(event.target.value as string);
	};

	const handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const newFilter = event.target.value as string;
		setSelectedFilter(newFilter);
		sendSelectedFilter(newFilter);
	};

	const sendSelectedFilter = (filter: string) => {
		if (props.onFilterChange) {
			props.onFilterChange(filter);
		}
	};

	const ScoreboardTable = [
		{
			name: 'Email',
			selector: (row: any) => row.email,
			// center: true,
			width: '40%',
			cell: (row: any, index: number) => (
				<div
					className='cohortReport__forBoldText'
					style={{
						marginLeft: '20px',
						display: 'flex',
						gap: '30px',
						flexWrap: 'nowrap',
					}}
				>
					<ToolTipOnText
						className='tool'
						ellipsisOnOverflow={
							!(
								document?.getElementsByClassName('tool')[index]?.clientWidth <=
								document?.getElementsByClassName('channel_name')[index]
									?.clientWidth
							)
						}
						disableInteraction={false}
						title={row.email}
					>
						{row.email}
					</ToolTipOnText>
					<Chip
						style={{
							background:
								row.stage_name_cat == 'closed won'
									? 'green'
									: row.stage_name_cat == 'closed lost'
									? 'red'
									: 'linear-gradient(44.87deg, #9149ff 10.76%, #e600ff 97.56%)',
						}}
						label={row.stage_name_cat}
						className='stage-chip'
					/>
				</div>
			),
		},
		{
			name: 'AI Lead Score',
			selector: (row: any) => row.lead_score,
			center: true,
			cell: (row: any) => (
				<div className='cohortReport__forBoldText'>
					<ToolTipOnText title={row.lead_score_ml} ellipsisOnOverflow>
						<div className='channel_name'>{row.lead_score_ml}</div>
					</ToolTipOnText>
				</div>
			),
		},
		{
			name: props?.connector + ' Score',
			selector: (row: any) => row.lead_score_ml,
			center: true,
			cell: (row: any) => (
				<ToolTipOnText title={row.lead_score} ellipsisOnOverflow>
					<div className='cohortReport__forBoldText'>{row.lead_score}</div>
				</ToolTipOnText>
			),
		},
	];

	return (
		<>
			{hitapi == true && <PageLoader />}
			<Grid
				style={{
					width: '100%',
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<div style={{ margin: '1rem 0rem' }}>
					<Typography variant='h4' component='span'>
						Score Board
					</Typography>
				</div>
				<Grid
					style={{
						display: 'flex',
						alignContent: 'center',
						flexWrap: 'wrap',
						gap: '20px',
					}}
				>
					<div style={{ width: '200px' }}>
						<Dropdown
							valueSelector='value'
							itemList={['Overall', 'Grade A', 'Grade B', 'Grade C']}
							label=''
							name='Grade'
							onChange={handleGradeChange}
							labelName='Filter By Grade'
							defaultValue={'Overall'}
						/>
					</div>
					<div style={{ width: '200px' }}>
						<Dropdown
							valueSelector='value'
							itemList={props?.Stages || ['All']}
							label=''
							name='Filter'
							onChange={handleFilterChange}
							labelName='Filter By Status'
							defaultValue={['All']}
						/>
					</div>
					<div style={{ marginRight: '10px' }}>
						<ToolTipOnText title='Share as Email'>
							<IconButton
								className='pinIcon bookmarkButtons'
								type='button'
								style={{
									height: '30px',
									width: '30px',
								}}
								onClick={() => {
									setCurrentRequest('contactScore');
									if (shareAttachmentRef.current) {
										shareAttachmentRef.current.handleClickOpen();
										shareAttachmentRef.current.flag = 3;
									}
								}}
							>
								<MailOutlineIcon color='primary' height='30px' width='30px' />
							</IconButton>
						</ToolTipOnText>
					</div>
				</Grid>
			</Grid>
			<Grid
				container
				style={{
					padding: '1rem',
					boxShadow: 'none',
					backgroundColor: theme.palette.mode == 'dark' ? '#1A1A1A' : '#F4F7FC',
					borderRadius: '20px',
				}}
			>
				{props?.isLoading ? (
					<CustomSkeleton
						variant={'rectangular'}
						height={500}
						width={'100%'}
						style={{ borderRadius: '20px' }}
					/>
				) : null}
				<Grid
					xs={12}
					style={{
						backgroundColor: theme.palette.mode == 'dark' ? 'black' : 'white',
						borderRadius: '20px',
						display: props?.isLoading ? 'none' : 'block',
					}}
				>
					<div className='HumanAITable'>
						<DataTable
							defineMargin={'1rem'}
							data={Data}
							scrollHeight={'100%'}
							columns={ScoreboardTable}
							topLeftandRightBorderRadius='10px'
							serverPagination
							showPagination
							handlePageChange={(event: any) => {
								props.offset(event);
							}}
							handlePerRowsChange={(event: any) => {
								props.limit(event);
							}}
							totalRows={TotalRows}
						/>
					</div>
				</Grid>
				{/* )} */}
			</Grid>
			<Suspense fallback={<h3>Loading&hellip;</h3>}>
				<AlertDialog
					ref={shareAttachmentRef}
					sx={{
						'& .MuiDialog-container': {
							'& .MuiPaper-root': {
								height: '320px',
								width: '100%',
								maxWidth: '660px', // Set your width here
							},
						},
					}}
					title='Share Table'
					customHTMLContent={shareAttachmentHTML}
					onConfirm={() => sethitapi(true)} // Correct invocation
					onDiscard={() => {
						if (shareAttachmentRef.current) {
							shareAttachmentRef.current.handleClose();
						}
					}}
					dialogTitleRootClass='shareReportroot'
					hideCloseButton
				/>
			</Suspense>
		</>
	);
};

export default ScoreBoard;
